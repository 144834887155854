<template>
  <validation-observer ref="form" v-slot="{ invalid }" slim>
    <layout width="420">
      <template slot="header">
        {{ title }}
      </template>

      <template>
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="Item name"
            rules="required"
          >
            <v-text-field
              v-model="state.name"
              label="Item name *"
              :error-messages="errors"
            >
              {{ state.name }}
            </v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Alert count"
            :rules="maxAlertCount"
          >
            <v-text-field
              v-model="state.alertCount"
              label="Alert count"
              :error-messages="errors"
            >
              {{ state.alertCount }}
            </v-text-field>
          </validation-provider>
          <form-subheader title="Value" />
          <validation-provider
            v-slot="{ errors }"
            name="Variable type"
            rules="required"
          >
            <type-selector
              v-model="state.type"
              label="Variable type *"
              :error-messages="errors"
            />
          </validation-provider>
          <v-text-field v-model="state.units" label="Units" />
          <v-text-field v-model="state.mask" label="Mask" />
          <v-textarea
            v-model="state.description"
            no-resize
            rows="1"
            auto-grow
            label="Description"
          >
            {{ state.description }}
          </v-textarea>
          <form-subheader title="Optional properties" />
          <div v-for="(property, index) in state.properties" :key="index">
            <validation-provider
              v-slot="{ errors }"
              name="Property name"
              rules="required"
            >
              <v-text-field
                v-model="property.property"
                label="Property name *"
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Variable type"
              rules="required"
            >
              <type-selector
                v-model="property.type"
                label="Variable type *"
                :error-messages="errors"
              />
            </validation-provider>
            <v-text-field v-model="property.units" label="Units" />
            <v-text-field v-model="property.mask" label="Mask" />
            <v-text-field
              v-model="property.defaultValue"
              label="Default value"
            />
            <v-btn
              class="my-2"
              outlined
              block
              color="error"
              @click="() => deleteProperty(index)"
            >
              Delete
            </v-btn>
          </div>
          <v-btn
            class="mt-4"
            outlined
            block
            color="primary"
            @click="addProperty"
          >
            Add property
          </v-btn>
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          :disabled="invalid"
          depressed
          :loading="loading"
          @click.stop="submit"
        >
          {{ submitBtnTitle }}
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onMounted, computed } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { monitoringItemService } from '@/modules/object-types/api';
import { schemasService } from '@/modules/common/api';
import TypeSelector from '@/components/common/TypeSelector';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { maxAlertCount } from '@/utils/validation/custom-validators';
import { required } from 'vee-validate/dist/rules';
import { typeCast } from '@/provider/utils';
import { getTypeIdByName } from '@/utils/getCurrentType';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'MonitoringItemEdit',
  components: {
    Layout,
    TypeSelector,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    parentSchemaId: {
      type: String,
      default: ''
    },
    monitoringItemId: {
      type: String,
      default: ''
    }
  },
  computed: {
    maxAlertCount() {
      return maxAlertCount;
    }
  },
  setup(props, { emit }) {
    const monitoringItem = ref({});
    const form = ref(null);
    const state = ref({
      name: '',
      description: '',
      type: '',
      units: '',
      mask: '',
      alertCount: 1,
      properties: []
    });
    const initialCustomPropertyIds = ref([]);

    const edit = computed(() => props.monitoringItemId);
    const title = computed(() =>
      edit.value ? 'Edit monitoring item' : 'Add monitoring item'
    );
    const submitBtnTitle = computed(() => (edit.value ? 'Save' : 'Add'));
    const iconItems = ref([]);

    const getInitialPropertyState = () => ({
      property: '',
      type: '',
      units: '',
      mask: '',
      defaultValue: ''
    });

    const handleSubmit = async () => {
      if (!(await form.value.validate())) return;

      let createdMonitoringItemId = props.monitoringItemId;
      if (!edit.value) {
        // create new monitoring item

        const schemaId = await monitoringItemService.fetchBaseMonitoringItemSchemaId();
        createdMonitoringItemId = await monitoringItemService.create({
          name: state.value.name,
          description: state.value.description,
          schemaId,
          parentSchemaId: props.parentSchemaId
        });
        monitoringItem.value = await monitoringItemService.fetch(
          createdMonitoringItemId
        );
      } else {
        await monitoringItemService.update(props.monitoringItemId, {
          name: state.value.name,
          description: state.value.description
        });

        // remove custom properties
        await Promise.all(
          initialCustomPropertyIds.value.map(id =>
            schemasService.deleteProperty(id)
          )
        );
      }
      await schemasService.updateValues(
        [
          {
            id: monitoringItem.value.infoName.id,
            value: state.value.name
          },
          {
            id: monitoringItem.value.commonType.id,
            value: state.value.type
          },
          {
            id: monitoringItem.value.commonUnits.id,
            value: state.value.units
          },
          {
            id: monitoringItem.value.commonMask.id,
            value: state.value.mask
          },
          {
            id: monitoringItem.value.commonAlertCount.id,
            value: Number(state.value.alertCount)
          }
        ],
        createdMonitoringItemId
      );
      await Promise.all(
        state.value.properties.map(property => {
          return schemasService.createProperty({
            schemaId: createdMonitoringItemId,
            property: property.property,
            typeId: getTypeIdByName(property.type),
            defaultValue: typeCast(property.type, property.defaultValue),
            mask: property.mask,
            units: property.units
          });
        })
      );
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onMounted(async () => {
      if (edit.value) {
        monitoringItem.value = await monitoringItemService.fetch(
          props.monitoringItemId
        );
        console.log(monitoringItem.value);
        const customProperties = Object.keys(monitoringItem.value)
          .filter(key => monitoringItem.value[key]?.groupName === 'Custom')
          .map(key => ({
            ...monitoringItem.value[key],
            type: monitoringItem.value[key].type.name
          }));
        initialCustomPropertyIds.value = customProperties.map(
          property => property.id
        );
        state.value = {
          name: monitoringItem.value.infoName.value,
          description: monitoringItem.value.description || '',
          type: monitoringItem.value.commonType.value,
          units: monitoringItem.value.commonUnits.value,
          mask: monitoringItem.value.commonMask.value,
          alertCount: monitoringItem.value.commonAlertCount.value,
          properties: customProperties
        };
      }
    });

    const addProperty = () => {
      state.value.properties.push(getInitialPropertyState());
    };

    const deleteProperty = propertyIndex => {
      state.value.properties.splice(propertyIndex, 1);
    };

    return {
      state,
      loading,
      title,
      submitBtnTitle,
      submit,
      edit,
      iconItems,
      form,
      addProperty,
      deleteProperty
    };
  }
};
</script>
